import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import { Popover, Box, Typography } from "@material-ui/core";

const AboutView = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const { anchorOrigin, transformOrigin, content, fontSize } = mapProps(props);
  return (
    <div>
      <InfoIcon
        aria-describedby={"about"}
        color="primary"
        onClick={handleClick}
        fontSize={fontSize}
        style={{ cursor: "pointer" }}
      />
      <Popover
        id={"about_popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Box p={2}>
          <Typography>{content}</Typography>
        </Box>
      </Popover>
    </div>
  );
};

const mapProps = props => {
  const anchorOrigin = props.anchorOrigin || {
    vertical: "bottom",
    horizontal: "left"
  };
  const transformOrigin = props.transformOrigin || {
    vertical: "top",
    horizontal: "right"
  };
  const content = props.content || "About";
  const fontSize = props.size || "large";
  return { anchorOrigin, transformOrigin, content, fontSize };
};

export default AboutView;
