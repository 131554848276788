import React, { useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  Button,
  MenuItem
} from "@material-ui/core";
import { toast } from "react-toastify";
import GridItem from "../../../../components/Grid/GridItem";

import TrendPlot from "../../../../components/DecompositionPlots/TrendPlot";
import SeasonalPlot from "../../../../components/DecompositionPlots/SeasonalPlot";
import ResidualPlot from "../../../../components/DecompositionPlots/ResidualPlot";
import { Nodes } from "../../../../utils/readers";
import { useMediaQuery } from "react-responsive";
import SelectorView from "../../../../components/SelectorView";
import AboutView from "../../../../components/AboutView";

const Decomposition = () => {
  const isDol = useMediaQuery({
    query: "(min-device-width: 1224px)"
  });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMob = useMediaQuery({ maxWidth: 767 });
  return <DecompositionView isDol={isDol} isTab={isTab} isMob={isMob} />;
};

const DecompositionView = ({ isDol, isTab, isMob }) => {
  const [nodeId, setNodeId] = useState(Nodes[15].uid);
  const handleSubmit = async nodeVal => {
    if (!nodeVal.length) {
      handleToast("warning", "Please Select A Node");
      return;
    }
    setNodeId(nodeVal);
  };
  const width = getWidth(isDol, isTab, isMob);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        width,
        margin: "auto",
        marginBottom: "50px"
      }}
    >
      <div>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <SelectorView handleSubmit={handleSubmit} />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end"
            }}
          >
            <AboutView
              content={
                "Decomposition plots showing trends, seasonality and residuality for a selected node till present day"
              }
              size={"small"}
            />
          </div>
        </GridItem>
      </div>
      {getView(TrendPlot, nodeId, width)}
      {getView(SeasonalPlot, nodeId, width)}
      {getView(ResidualPlot, nodeId, width)}
    </div>
  );
};

const getView = (Component, nodeId, width) => {
  return (
    <div style={{ width: "100%" }}>
      <GridItem xs={12} sm={12} md={12} lg={12} style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <Component nodeId={nodeId} />
        </div>
      </GridItem>
    </div>
  );
};
const handleToast = (type, message) => {
  message = message || "";
  let toastProp = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
  };
  if (type === "error") {
    toast.error(message, toastProp);
    return;
  }

  if (type === "warning") {
    toast.warn(message, toastProp);
    return;
  }
  toast(message, toastProp);
};

const getWidth = (isDol, isTab, isMob) => {
  if (isMob) return "100%";
  if (isTab) return "90%";
  return "80%";
};
export default Decomposition;
