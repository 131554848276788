import React from "react";
import { withFirebase } from "../Firebase";
import { formatDataForPrediction } from "../../utils/data";
import moment from "moment";
import Chart from "react-google-charts";
import CircularProgressView from "../CircularProgress";
import AboutView from "../AboutView";

class PredictionPlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      readerVal: []
    };
  }

  runQuery = async nodeVal => {
    this.setState({ isLoading: true });
    const db = this.props.firebase.db;

    let promises = [];
    let results = [];
    let { start, end } = getUnixValueDayStartEnd(this.props.yesterday);

    let predictionPromise = db
      .collection("predictions")
      .doc(nodeVal)
      .collection("data")
      .where("createdAt", ">=", start)
      .where("createdAt", "<=", end)
      .get();
    promises.push(predictionPromise);
    let reportPromise = db
      .collection("reports")
      .doc(nodeVal)
      .collection("data")
      .where("createdAt", ">=", start)
      .where("createdAt", "<=", end)
      .get();
    promises.push(reportPromise);
    const all = Promise.all(promises);

    // get the result of all the promises
    results = await all.catch(e => {});

    // format data for table
    if (results.length === 2) {
      let predictionData = results[0].docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      let reportData = results[1].docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      let data = formatDataForPrediction(predictionData, reportData);
      this.setState({ readerVal: data });
    }

    this.setState({ isLoading: false });
  };

  componentDidMount = () => {
    this.runQuery(this.props.nodeId);
  };

  componentDidUpdate = prevProps => {
    if (prevProps.nodeId !== this.props.nodeId) {
      this.runQuery(this.props.nodeId);
    }
  };

  render = () => {
    return (
      <PredictionPlotView
        yesterday={this.props.yesterday}
        readerVal={this.state.readerVal}
        isLoading={this.state.isLoading}
      />
    );
  };
}

const PredictionPlotView = props => {
  const { yesterday, readerVal, isLoading } = props;
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          height: "350px"
        }}
      >
        <CircularProgressView />
      </div>
    );
  }
  let emptyData = "Empty Data. Please Select A Node or A Different Node";
  if (readerVal.length <= 1) {
    return (
      <div style={{ textAlign: "center", height: "350px" }}>{emptyData}</div>
    );
  }
  return (
    <div id="chart">
      <Chart
        width={"100%"}
        height={350}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={readerVal}
        options={{
          legend: { position: "top", alignment: "end" },
          title: `Prediction for ${getTitle(yesterday)}`,
          hAxis: {
            title: "Time",
            titleTextStyle: {
              fontName: "Montserrat"
            }
          },
          vAxis: {
            title: "PM 2.5 μg/m³",
            titleTextStyle: {
              fontName: "Montserrat"
            }
          },
          series: {
            1: { curveType: "function" }
          }
        }}
        rootProps={{ "data-testid": "1" }}
      />
    </div>
  );
};

const getUnixValueDayStartEnd = yesterday => {
  if (yesterday) {
    return {
      start: moment()
        .subtract(1, "day")
        .startOf("day")
        .unix(),
      end: moment()
        .subtract(1, "day")
        .endOf("day")
        .unix()
    };
  }

  return {
    start: moment()
      .startOf("day")
      .unix(),
    end: moment()
      .endOf("day")
      .unix()
  };
};

const getTitle = yesterday => {
  return yesterday
    ? moment()
        .subtract(1, "day")
        .format("DD/MM/YYYY")
    : moment().format("DD/MM/YYYY");
};
export default withFirebase(PredictionPlot);
