// import { getMarkerDetails } from "../../utils/utils";
import ReactDOMServer from "react-dom/server";
import React from "react";
// import { Marker } from "react-google-maps";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { getMarkerDetails } from "../../utils/utils";
import moment from 'moment';

export default class MarkerView extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribe = null;
    this.timer = null;
    // the pmLevel in inital state is a number
    // but from data we are getting it as a string
    this.state = {
      pmLevel: 0,
      lastUpdated: "",
      timeInterval: 50000
    };
  }

  handleDataFetching = async () => {
    const db = this.props.firebase.db;
    const snapshot = await db
      .collection("nodes")
      .doc(this.props.marker.uid)
      .collection("data")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
    const data = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    });
    if (data.length > 0) {
      let mins = getDiffInMin(data);
      if (typeof mins === 'number' && mins < 2) {
        this.setState({
          pmLevel: data[0]["pm2_5"],
          lastUpdated: data[0].id
        });
      }
    }
  };

  componentDidMount() {
    this.handleDataFetching();
    this.timer = setInterval(this.handleDataFetching, this.state.timeInterval);
  }

  componentWillUnmount = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  render = () => {
    const { marker, showLabel } = this.props;
    let { pmLevel, lastUpdated } = this.state;
    if (pmLevel === "0" || pmLevel <= 0) return null;
    return (
      <MarkerWithLabel
        options={{ pmLevel: this.state.pmLevel }}
        title={`${pmLevel}_${marker.loc_name}_${lastUpdated}`}
        position={{
          lat: marker.lat,
          lng: marker.lng
        }}
        icon={{ url: Icon(pmLevel) }}
        // if pmLevel is of more than one digit you have to adjust the x for anchor
        labelAnchor={getLabelAnchor(pmLevel)}
        labelStyle={{ fontSize: "14px" }}
        onClick={() => {
          this.props.onClick(getMarkerPopupInfo(marker, pmLevel, lastUpdated));
        }}
      >
        {getLabel(showLabel, pmLevel)}
      </MarkerWithLabel>
    );
  };
}

const getLabel = (showLabel, pmLevel) => {
  if (showLabel) {
    return <div>{`${pmLevel}`}</div>
  }
  return <div />
}

const getMarkerPopupInfo = (marker, pmLevel, lastUpdated) => {
  let obj = {};
  obj.header = marker.loc_name;
  obj.lat = marker.lat;
  obj.lng = marker.lng;
  let info = [];
  info.push(`PM 2.5: ${pmLevel} μg/m³`);
  info.push(`Last Updated : ${lastUpdated}`);
  info.push(`Courtesy : aerogram.in`);
  obj.info = info;
  return obj;
};

const getLabelAnchor = pmLevel => {
  let xVal = 5;
  pmLevel = typeof pmLevel === "number" ? pmLevel + "" : pmLevel;
  let charCount = pmLevel.length;
  if (charCount > 1 && charCount < 3) {
    xVal = 8;
  }
  if (charCount > 2) {
    xVal = 14;
  }
  return new window.google.maps.Point(xVal, 34);
};

const Icon = (pmLevel = 0) => {
  let { w, h, ac } = getMarkerDetails(pmLevel);
  w = 35;
  h = 35;
  let svgView = (
    <svg
      height={`${h}pt`}
      viewBox="0 0 512 512"
      width={`${w}pt`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m512 256c0 141.386719-114.613281 256-256 256s-256-114.613281-256-256 114.613281-256 256-256 256 114.613281 256 256zm0 0"
        fill={ac}
      />
    </svg>
  );
  return (
    "data:image/svg+xml," + escape(ReactDOMServer.renderToStaticMarkup(svgView))
  );
};

const getDiffInMin = (data) => {
  let diff = 5;
  try {
    diff = moment().diff(moment.unix(data[0]['createdAt']), 'minutes')
  } catch (e) {

  }
  return diff;
}

