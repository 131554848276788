import React, { useState } from "react";
import { withGoogleMap, withScriptjs } from "react-google-maps";
import { GoogleMap } from "react-google-maps";
import {
  mapDefaultOptions,
  mapWrapperStylings,
  mapWrapperContainerStyle,
  MapUrl
} from "./mapSettings";
import { withFirebase } from "../../components/Firebase";
import { IIT_D_LL, ClusterIcon, PM_DETAILS } from "../../utils/utils";
import MarkerView from "../../components/Marker";
import Legend from "../../components/Legend";
import { Nodes } from "../../constants/readers";
import { OpenAQINodes } from "../../constants/open_aqi_readers";
import OpenAQIMarkerView from "../../components/OpenAQIMarker";
import InfoWindow from "../../components/InfoWindow";
import {
  SettingsModal,
  SettingsButton,
  ZoomOutButton,
  DataAnalyticsButton
} from "../../components/SettingsModal";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import { useHistory } from "react-router-dom";

const MapWrapped = withScriptjs(withGoogleMap(Map));
class MapHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: Nodes,
      aqiData: OpenAQINodes
    };
    console.log("Aerogram IIT Delhi _dev");
  }
  render = () => {
    return (
      <div style={mapWrapperStylings}>
        <MapWrapped
          googleMapURL={MapUrl}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={mapWrapperContainerStyle} />}
          mapElement={<div style={{ height: `100%` }} />}
          data={this.state.data}
          aqiData={this.state.aqiData}
          firebase={this.props.firebase}
        />
      </div>
    );
  };
}

function Map(props) {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showLabel, setShowLabel] = useState(true);
  const [zoom, setZoom] = useState(15);
  let mapRef = null;
  // check zoom feature throughly, its is not working as desired
  const handleZoomChanged = () => {
    if (mapRef.getZoom() && typeof mapRef.getZoom() === "number") {
      setZoom(mapRef.getZoom());
    }
  };
  const setZoomToDelhi = () => {
    setZoom(11);
  };
  // const browserHistory = useHistory();
  return (
    <div>
      <GoogleMap
        ref={map => (mapRef = map)}
        zoom={zoom}
        defaultCenter={{ lat: IIT_D_LL[0], lng: IIT_D_LL[1] }}
        defaultOptions={mapDefaultOptions}
        onZoomChanged={handleZoomChanged}
      >
        <MarkerClusterer
          calculator={(markers, styles) =>
            markerClusterCalculator(markers, styles, showLabel)
          }
          styles={getMarkerClusterStyles()}
        >
          {renderMarker({ ...props, setSelectedMarker, showLabel })}
          {renderAQIMarkers({ ...props, setSelectedMarker, showLabel })}
        </MarkerClusterer>
      </GoogleMap>
      <Legend />
      <SettingsButton showModal={() => setShowModal(true)} />
      <ZoomOutButton setZoomToDelhi={setZoomToDelhi} />
      {/* <br />
      <DataAnalyticsButton
        redirectToData={() => {
          browserHistory.push("/data");
        }}
      /> */}
      {renderModal({ showModal, setShowModal, showLabel, setShowLabel })}
      {
        <InfoWindow
          selectedMarker={selectedMarker}
          setSelectedMarker={setSelectedMarker}
        />
      }
    </div>
  );
}

function renderMarker(props) {
  if (!props.data.length) return null;
  return props.data.map((marker, index) => {
    return (
      <MarkerView
        key={index}
        marker={marker}
        firebase={props.firebase}
        index={index}
        showLabel={props.showLabel}
        onClick={markerInfo => {
          props.setSelectedMarker(markerInfo);
        }}
      />
    );
  });
}

function renderAQIMarkers(props) {
  if (!props.aqiData.length) return null;
  return props.aqiData.map((marker, index) => {
    return (
      <OpenAQIMarkerView
        key={index}
        marker={marker}
        index={index}
        showLabel={props.showLabel}
        onClick={markerInfo => {
          props.setSelectedMarker(markerInfo);
        }}
      />
    );
  });
}

function renderModal(props) {
  const { showModal, setShowModal, setShowLabel, showLabel } = props;
  if (!showModal) return null;
  const handleShowLabel = () => {
    setShowLabel(!showLabel);
  };
  return (
    <SettingsModal
      closeModal={() => setShowModal(false)}
      handleShowLabel={handleShowLabel}
      showLabel={showLabel}
    />
  );
}

function markerClusterCalculator(markers, style, showLabel) {
  let totalPM = 0;
  for (let marker of markers) {
    let mpl = marker.pmLevel;
    if (mpl) {
      if (typeof mpl === "string") {
        mpl = parseInt(mpl);
      }
      totalPM += mpl;
    }
  }
  let count = markers.length || 1;
  let val = Math.floor(totalPM / count);
  let styleIndex = 0;
  for (let i = 0; i < PM_DETAILS.length; i++) {
    if (isInBetween(PM_DETAILS[i].r, val)) {
      styleIndex = i;
      break;
    }
    // eventually if nothing is found we assume that it is a very large pm value
    // hence the last index will be set
    styleIndex = i;
  }
  if (!showLabel) {
    val = "";
  }
  styleIndex = styleIndex + 1;
  if (styleIndex > 5) {
    styleIndex = 6;
  }
  return {
    text: `${val}`,
    index: styleIndex
  };
}

function getMarkerClusterStyles() {
  let arr = [];
  for (let i = 0; i < PM_DETAILS.length; i++) {
    let pm = PM_DETAILS[i];
    let iconInfo = {
      url: ClusterIcon(pm.r[pm.r.length - 1]),
      height: 80,
      lineHeight: 80,
      width: 80,
      textSize: 16
    };
    arr.push(iconInfo);
  }
  return arr;
}

const isInBetween = (arr, value) => {
  if (value > arr[0] && value <= arr[1]) return true;
  return false;
};

export default withFirebase(MapHome);
