import React, { useState } from "react";

import { toast } from "react-toastify";
import GridItem from "../../../../components/Grid/GridItem";
import PredictionPlot from "../../../../components/PredictionPlot";
import { Nodes } from "../../../../utils/readers";
import { useMediaQuery } from "react-responsive";
import SelectorView from "../../../../components/SelectorView";
import AboutView from "../../../../components/AboutView";

const Prediction = () => {
  // is desktop or laptop
  const isDol = useMediaQuery({
    query: "(min-device-width: 1224px)"
  });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMob = useMediaQuery({ maxWidth: 767 });
  return <PredictionView isDol={isDol} isTab={isTab} isMob={isMob} />;
};

const PredictionView = ({ isDol, isTab, isMob }) => {
  const [nodeId, setNodeId] = useState(Nodes[15].uid);
  const handleSubmit = async nodeVal => {
    if (!nodeVal.length) {
      handleToast("warning", "Please Select A Node");
      return;
    }
    setNodeId(nodeVal);
  };
  const width = getWidth(isDol, isTab, isMob);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        width,
        margin: "auto",
        marginBottom: "50px"
      }}
    >
      <div>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <SelectorView handleSubmit={handleSubmit} />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end"
            }}
          >
            <AboutView
              content={
                "A plot which maps predicted PM 2.5 value against actual PM 2.5 value, graphs are available for both present and previous day"
              }
              size={"small"}
            />
          </div>
        </GridItem>
      </div>
      <div style={{ width: "100%" }}>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <PredictionPlot nodeId={nodeId} yesterday={false} />
          </div>
        </GridItem>
      </div>
      <br />
      <br />
      <div style={{ width: "100%" }}>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <PredictionPlot nodeId={nodeId} yesterday={true} />
          </div>
        </GridItem>
      </div>
      <br />
      <br />
    </div>
  );
};

const handleToast = (type, message) => {
  message = message || "";
  let toastProp = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
  };
  if (type === "error") {
    toast.error(message, toastProp);
    return;
  }

  if (type === "warning") {
    toast.warn(message, toastProp);
    return;
  }
  toast(message, toastProp);
};

const getWidth = (isDol, isTab, isMob) => {
  if (isMob) return "100%";
  if (isTab) return "90%";
  return "80%";
};
export default Prediction;
