import React, { useState } from "react";
import { withGoogleMap, withScriptjs } from "react-google-maps";
import { GoogleMap } from "react-google-maps";
import { mapDefaultOptions, mapWrapperStylings, mapWrapperContainerStyle, MapUrl } from "./mapSettings";
import MarkerView from "./Marker";
import Legend from "./Legend";
import InfoWindow from "./InfoWindow";
import { Nodes } from "../../../utils/readers";
import { IIT_D_LL } from "../../../utils/utils";
import { withFirebase } from "../../Firebase";

const MapWrapped = withScriptjs(withGoogleMap(Map));
class MapView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: Nodes,
        };
    }
    render = () => {
        return (
            <div style={mapWrapperStylings} >
                <MapWrapped
                    googleMapURL={MapUrl}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={mapWrapperContainerStyle} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    data={this.state.data}
                    firebase={this.props.firebase}
                />
            </div>
        );
    };
}

function Map(props) {
    const [selectedMarker, setSelectedMarker] = useState(null);

    return (
        <div style={{ position: 'relative' }}>
            <GoogleMap
                zoom={15}
                defaultCenter={{ lat: IIT_D_LL[0], lng: IIT_D_LL[1] }}
                defaultOptions={mapDefaultOptions}
            >
                {renderMarker({ ...props, setSelectedMarker, showLabel: true })}
            </GoogleMap>
            <Legend />
            {<InfoWindow
                selectedMarker={selectedMarker}
                setSelectedMarker={setSelectedMarker}
            />}
        </div>
    );
}

function renderMarker(props) {
    if (!props.data.length) return null;
    return props.data.map((marker, index) => {
        return (
            <MarkerView
                key={index}
                marker={marker}
                firebase={props.firebase}
                index={index}
                showLabel={props.showLabel}
                onClick={markerInfo => {
                    props.setSelectedMarker(markerInfo);
                }}
            />
        );
    })
}



export default withFirebase(MapView);
