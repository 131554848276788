import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChartIcon from "@material-ui/icons/BarChart";
import TimelineIcon from "@material-ui/icons/Timeline";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import HomeOutlined from "@material-ui/icons/HomeOutlined";

export const getMainListItems = handleItemClick => {
  return (
    <div>
      {renderListItem(HOME, HomeOutlined, handleItemClick)}
      {renderListItem(DASHBOARD, DashboardIcon, handleItemClick)}
      {renderListItem(HEATMAP, BarChartIcon, handleItemClick)}
      {renderListItem(CANDLE, ViewColumnIcon, handleItemClick)}
      {renderListItem(PREDICTION, TimelineIcon, handleItemClick)}
      {renderListItem(DECOMPOSITION, BarChartIcon, handleItemClick)}
    </div>
  );
};

const renderListItem = (viewName, Icon, handleItemClick) => {
  return (
    <ListItem
      button
      onClick={() => {
        handleItemClick(viewName);
      }}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={viewName} />
    </ListItem>
  );
};
export const HOME = "Home";
export const DASHBOARD = "Stats";
export const REPORT = "Report";
export const CANDLE = "Candle Sticks";
export const DECOMPOSITION = "Decompositions";
export const PREDICTION = "Predictions";
export const HEATMAP = "Heat Maps";
