const moment = require("moment");
export const formatData = (data, pmVarName = "pm2_5", label = "node") => {
  let result = [["x", label]];
  let dateAndVal = {};
  for (let p of data) {
    let stamp = moment(p.createdAt * 1000).format("DD/MM,HH:mm");
    if (!dateAndVal[stamp]) {
      dateAndVal[stamp] = {};
    }
    dateAndVal[stamp][pmVarName] = p[pmVarName];
  }
  for (let key in dateAndVal) {
    let date = key;
    let value = dateAndVal[key][pmVarName];
    result.push([date, value]);
  }
  return result;
};

export const formatCandleData = data => {
  let result = [];
  result.push([
    {
      type: "string",
      id: "Date"
    },
    {
      type: "number",
      label: "min"
    },
    {
      type: "number",
      label: "start"
    },
    {
      type: "number",
      label: "end"
    },
    {
      type: "number",
      label: "max"
    },
    {
      type: "string",
      role: "tooltip"
    }
  ]);
  for (let val of data) {
    let max = val["max_pm2_5"];
    let min = val["min_pm2_5"];
    let start = val["start_pm2_5"];
    let end = val["end_pm2_5"];
    result.push([
      moment.unix(val.createdAt).format("DD/MM"),
      max,
      start,
      end,
      min,
      `Max - ${max}, Min - ${min}, Open - ${start}, Close - ${end}`
    ]);
  }
  return result;
};

export const formatDataForPrediction = (predictionData, reportData) => {
  let result = [["x", "prediction", "original"]];
  let dateAndVal = {};
  for (let p of predictionData) {
    let stamp = moment(p.createdAt * 1000).format("HH:mm a");
    if (!dateAndVal[stamp]) {
      dateAndVal[stamp] = {};
    }
    dateAndVal[stamp]["p_pm2_5"] = p["pm2_5"];
  }
  for (let r of reportData) {
    let stamp = moment(r.createdAt * 1000).format("HH:mm a");
    if (dateAndVal[stamp]) {
      dateAndVal[stamp]["r_pm2_5"] = r["avg_pm2_5"];
    }
  }
  for (let key in dateAndVal) {
    let date = key;
    let prediction = dateAndVal[key]["p_pm2_5"];
    let original = dateAndVal[key]["r_pm2_5"];
    result.push([date, prediction, original]);
  }
  return result;
};

export const formatDataForLineGraph = (data, label = "") => {
  let result = [["x", label]];
  let dateAndVal = {};
  for (let p of data) {
    let stamp = moment(p.createdAt * 1000).format("DD/MM,HH:mm");
    if (!dateAndVal[stamp]) {
      dateAndVal[stamp] = {};
    }
    dateAndVal[stamp]["pm2_5"] = p["pm2_5"];
  }
  for (let key in dateAndVal) {
    let date = key;
    let value = dateAndVal[key]["pm2_5"];
    result.push([date, value]);
  }
  return result;
};
