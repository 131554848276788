import React, { useState } from "react";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import ActiveNodeStats from "./Stats/ActiveNodes/index.js";
import NodeGraphView from "./NodeGraphView/index.js";
import MapView from "./MapView/index.js";
import Card from "../Card/Card.js";
import HourlyVariation from "./Stats/HourlyVariation/index.js";
import PMTable from "./Stats/PMTable/index.js";
import { Nodes } from "../../utils/readers.js";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  Button,
  MenuItem
} from "@material-ui/core";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import SelectorView from "../SelectorView/index.js";

const DashboardView = () => {
  const isDol = useMediaQuery({
    query: "(min-device-width: 1224px)"
  });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMob = useMediaQuery({ maxWidth: 767 });
  const width = getWidth(isDol, isTab, isMob);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width,
        margin: "auto"
      }}
    >
      <br />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%"
        }}
      >
        <GridItem xs={12} sm={12} md={4} lg={4}>
          <ActiveNodeStats />
          <PMTable />
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <Card style={{ marginTop: "0px", padding: "10px" }}>
            <MapView />
          </Card>
        </GridItem>
      </div>
      <br />
      <br />
      <SingleNodeStatsContainer />
    </div>
  );
};

const SingleNodeStatsContainer = () => {
  const [nodeId, setNodeId] = useState(Nodes[15].uid);
  const handleSubmit = async nodeVal => {
    if (!nodeVal.length) {
      handleToast("warning", "Please Select A Node");
      return;
    }
    setNodeId(nodeVal);
  };

  return <SingleNoteStatsView handleSubmit={handleSubmit} nodeId={nodeId} />;
};

const SingleNoteStatsView = ({ handleSubmit, nodeId }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        width: "100%",
        marginBottom: "50px"
      }}
    >
      <div>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{ marginLeft: "-15px" }}>
            <SelectorView handleSubmit={handleSubmit} />
          </div>
        </GridItem>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%"
        }}
      >
        <GridItem xs={12} sm={4} md={4} lg={4}>
          <HourlyVariation nodeId={nodeId} />
        </GridItem>
        <GridItem xs={12} sm={8} md={8} lg={8}>
          <NodeGraphView nodeId={nodeId} />
        </GridItem>
      </div>
    </div>
  );
};

const handleToast = (type, message) => {
  message = message || "";
  let toastProp = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
  };
  if (type === "error") {
    toast.error(message, toastProp);
    return;
  }

  if (type === "warning") {
    toast.warn(message, toastProp);
    return;
  }
  toast(message, toastProp);
};

const getWidth = (isDol, isTab, isMob) => {
  if (isMob) return "100%";
  if (isTab) return "90%";
  return "80%";
};

export default DashboardView;
