import React from "react";
import Typography from "@material-ui/core/Typography";
import Title from "../Title";
import Card from "../../../Card/Card";
import { withFirebase } from "../../../Firebase/index.js";
import { getDateFromEpoch } from "../../../../utils/utils";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button } from "@material-ui/core";
import moment from "moment";
import AboutView from "../../../AboutView";
// DO ONE THING
// LET THE NODE SELECCTION FOR GRAPH AND HOURLY CHANGE VALUE AND WEEKLY CHANGE
// VALUE BE SAME
class HourlyVariation extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribe = null;
    this.timer = null;
    this.state = {
      change: 0,
      presentVal: 0,
      timeInterval: 30000,
      lastUpdated: getDateFromEpoch(null, "HH:mm:ss")
    };
  }

  handleDataFetching = async () => {
    const db = this.props.firebase.db;
    const snapshot = await db
      .collection("reports")
      .doc(this.props.nodeId)
      .collection("data")
      .orderBy("createdAt", "desc")
      .limit(2)
      .get();
    const data = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    });
    if (data.length === 2) {
      let previousVal = data[1]["avg_pm2_5"];
      let presentVal = data[0]["avg_pm2_5"];
      let change = (((presentVal - previousVal) / presentVal) * 100).toFixed(2);
      let lastUpdated = getDateFromEpoch(data[0].createdAt * 1000, "HH:mm:ss");
      presentVal = presentVal.toFixed(2);
      this.setState({ presentVal, change, lastUpdated });
      return;
    }
    this.setState({
      presentVal: 0,
      change: 0,
      lastUpdated: getDateFromEpoch(moment().unix() * 1000, "HH:mm:ss")
    });
  };

  componentDidMount() {
    this.handleDataFetching();
  }

  componentDidUpdate = prevProps => {
    if (prevProps.nodeId !== this.props.nodeId) {
      this.handleDataFetching();
    }
  };

  getPercentChangeText = change => {
    if (change === 0) return `${change}`;
    if (change < 0) {
      return `- ${Math.abs(change)}`;
    }
    return `+ ${Math.abs(change)}`;
  };

  render = () => {
    return (
      <Card style={{ padding: "10px", marginTop: "0px" }}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <Title>Hourly PM 2.5</Title>
          <span style={{ marginLeft: "auto" }}>
            <InfoView />
          </span>
          {/* <span style={{ marginLeft: "auto" }}>
            <Button
              onClick={() => {
                this.handleDataFetching();
              }}
            >
              <RefreshIcon />
            </Button>
          </span> */}
        </div>
        <div style={{ margin: "10px" }}>
          <Typography component="p" variant="h3">
            {`${this.state.presentVal}`}
            <span style={{ fontSize: "14px" }}>{` μg/m³`}</span>
          </Typography>
        </div>
        <div style={{ margin: "10px" }}>
          <Typography
            component="p"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span style={{ fontSize: "16px" }}>{`${this.getPercentChangeText(
              this.state.change
            )} %`}</span>
            <span
              style={{ fontSize: "14px" }}
            >{`Updated At : ${this.state.lastUpdated}`}</span>
          </Typography>
        </div>
      </Card>
    );
  };
}

const InfoView = () => (
  <AboutView
    content={"Shows PM 2.5 value for the past hour for selected node"}
    size={"small"}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
  />
);

export default withFirebase(HourlyVariation);
