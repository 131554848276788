import React from "react";
import ReactDOMServer from "react-dom/server";
const moment = require("moment");

export const PM_DETAILS = [
  { c: "#8AB8FF", ac: "#8AB8FFAF", r: [0, 50] },
  { c: "#FFEE52", ac: "#FFEE52AF", r: [50, 100] },
  { c: "#F2CC0C", ac: "#F2CC0CAF", r: [100, 150] },
  { c: "#FF780A", ac: "#FF780AAF", r: [150, 200] },
  { c: "#E02F44", ac: "#E02F44AF", r: [200, 250] },
  { c: "#C4162A", ac: "#C4162AAF", r: [250, 300] }
];
export const IIT_D_LL = [28.545, 77.1926];
const isInBetween = (arr, value) => {
  if (value > arr[0] && value <= arr[1]) return true;
  return false;
};

const MAX_RAD = 100;
export const getMarkerDetails = value => {
  if (typeof value === "string") value = parseInt(value);
  // default value
  let pmLevel = PM_DETAILS[0];
  for (let val of PM_DETAILS) {
    if (isInBetween(val.r, value)) {
      pmLevel = val;
      break;
    }
  }
  // what to do if value is less than 0 and greater than 300
  // if(value < 0 ) pmLevel =PM_DETAILS[0];
  // if value is more than 300
  let lastPmDetail = PM_DETAILS[PM_DETAILS.length - 1];
  if (value > lastPmDetail.r[1]) {
    pmLevel = lastPmDetail;
    //value = lastPmDetail.r[1];
  }
  let r = (value / pmLevel.r[1]) * MAX_RAD;
  if (r <= 0) r = 10;
  return { w: r, h: r, ac: pmLevel.ac, c: pmLevel.c };
};

export const formatAQIData = data => {
  let result = [];
  try {
    for (let d of data) {
      let obj = {};
      if (!d.location) {
        continue;
      }
      obj.loc_name = d.location;
      if (!d.coordinates) {
        continue;
      }
      if (!d.coordinates.latitude || !d.coordinates.longitude) {
        continue;
      }
      obj.lat = d.coordinates.latitude;
      obj.lng = d.coordinates.longitude;
      let foundPm = false;
      for (let value of d.measurements) {
        if (value.parameter === "pm25") {
          obj.pmLevel = Math.floor(value.value);
          obj.unit = value.unit;
          foundPm = true;
          break;
        }
      }
      if (!foundPm) {
        obj.pmLevel = 0;
        obj.unit = "µg/m³";
      }
      result.push(obj);
    }
  } catch (e) {
    return result;
  }
  return result;
};

export const getDateDiffInDays = past => {
  let today = moment(); //.format('YYYY-MM-DD');
  let pastDate = moment(past);
  let dayDiff = today.diff(pastDate, "days");
  return dayDiff < 2;
};

export const ClusterIcon = (pmLevel = 0) => {
  let { w, h, ac, c } = getMarkerDetails(pmLevel);
  w = 60;
  h = 60;
  let svgView = getSvg(h, w, ac, c);
  return (
    "data:image/svg+xml," + escape(ReactDOMServer.renderToStaticMarkup(svgView))
  );
};

export const getSvg = (h, w, ac, c) => (
  <svg
    height={`${h}pt`}
    viewBox="0 0 512 512"
    width={`${w}pt`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="250" cy="250" r="220" fill={ac} stroke={ac} strokeWidth="8" />
    <circle
      cx="250"
      cy="250"
      r="150"
      fill={ac}
      stroke={"white"}
      strokeWidth="8"
    />
  </svg>
);

export const getHourRangesBetweenIntervals = (startSecond, endSecond) => {
  let hourSecs = 3600;
  let range = [];
  if (endSecond - startSecond < hourSecs) {
    // sending a range worth of an hour only
    range.push([startSecond, startSecond + hourSecs]);
    return range;
  }
  let start = startSecond;
  let nextVal = startSecond + hourSecs;
  while (true) {
    if (nextVal > endSecond) {
      break;
    }
    range.push([start, nextVal]);
    start = nextVal;
    nextVal = start + hourSecs;
  }
  return range;
};

export const getDateFromEpoch = (epoch, format = "DD/MM/YYYY") => {
  if (!epoch) return moment().format(format);

  try {
    return moment(epoch).format(format);
  } catch (e) {
    return moment().format(format);
  }
};
