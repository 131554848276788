import React from "react";
import Title from "../Title";
import Card from "../../../Card/Card";
import { withFirebase } from "../../../Firebase/index.js";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Nodes } from "../../../../utils/readers";
import { Button, makeStyles } from "@material-ui/core";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AboutView from "../../../AboutView";

class PMTable extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribe = null;
    this.timer = null;
    this.state = {
      tableData: [],
      timeInterval: 120000
    };
  }

  handleDataFetching = async () => {
    let twoMinBeforeEpoch = moment()
      .subtract(2, "minutes")
      .unix();
    const db = this.props.firebase.db;
    let promises = [];
    let tableArr = [];
    let results = [];
    // collect all promise in an array
    for (let node of Nodes) {
      const p = db
        .collection("nodes")
        .doc(node.uid)
        .collection("data")
        .where("createdAt", ">=", twoMinBeforeEpoch)
        .limit(1)
        .get();
      // we need to add node.uid somewhere
      // so that it can be used in result
      promises.push(
        p.then(snapshot => {
          const data = snapshot.docs.map(doc => {
            return {
              nodeId: node.uid,
              loc: node.loc_name,
              id: doc.id,
              ...doc.data()
            };
          });
          return data;
        })
      );
    }
    // run them simultaneouly, previously they were executed sequentially
    const all = Promise.all(promises);

    // get the result of all the promises
    results = await all.catch(e => {});

    // format data for table
    results.forEach(data => {
      if (data.length) {
        data = data[0];
        tableArr.push({
          nodeId: data.nodeId,
          loc: data.loc,
          id: data.id,
          pm2_5: parseFloat(data["pm2_5"])
        });
      }
    });

    // sort in decreasing order
    tableArr.sort((a, b) => b["pm2_5"] - a["pm2_5"]);
    this.setState({ tableData: tableArr });
  };

  componentDidMount() {
    this.handleDataFetching();
    this.timer = setInterval(this.handleDataFetching, this.state.timeInterval);
  }

  componentWillUnmount = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  render = () => {
    return (
      <Card style={{ padding: "10px", marginTop: "0px" }}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <Title>PM 2.5 Levels</Title>
            <InfoView />
          </div>
        </div>
        <div>
          <div
            style={{ minWidth: "100px", maxHeight: "300px", overflowY: "auto" }}
            component={Paper}
          >
            <Table size="small" aria-label="dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Location</TableCell>
                  <TableCell align="right">μg/m³</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tableData.map(row => (
                  <TableRow key={row.nodeId}>
                    <TableCell component="th" scope="row">
                      {row.loc}
                    </TableCell>
                    <TableCell align="center">{row.pm2_5}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </Card>
    );
  };
}

const InfoView = () => (
  <AboutView
    content={
      "Shows realtime PM 2.5 values for online nodes in decreasing order"
    }
    size={"small"}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
  />
);

export default withFirebase(PMTable);
