import React from "react";
import { withFirebase } from "../../../../components/Firebase";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import ProgressiveImage from "react-progressive-image";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AboutView from "../../../../components/AboutView";

const getArrowStyles = (isDol, isMob, isTab, isLeftArrow, isRightArrow) => {
  let obj = {
    position: "absolute",
    zIndex: 2,
    cursor: "pointer",
    border: 0
  };

  if (isLeftArrow) {
    if (isDol) return { ...obj, top: "calc(50% - 15px)", left: 15 };
    if (isTab || isMob) return { ...obj, top: "calc(90% - 15px)", left: 15 };
    if (isTab || isMob) return { ...obj, top: "calc(90% - 15px)", left: 15 };
    return { ...obj, top: "calc(90% - 15px)", left: 15 };
  }

  if (isRightArrow) {
    if (isDol) return { ...obj, top: "calc(50% - 15px)", right: 15 };
    if (isTab || isMob) return { ...obj, top: "calc(90% - 15px)", right: 15 };
    if (isTab || isMob) return { ...obj, top: "calc(90% - 15px)", right: 15 };
    return { ...obj, top: "calc(90% - 15px)", right: 15 };
  }
};

class IITDelhiHeatMap extends React.Component {
  state = {
    data: []
  };
  async componentDidMount() {
    const db = this.props.firebase.db;
    const dId = this.props.firebase.documentId;
    let duration = moment.duration({ hours: 5, minutes: 30 });
    let dateTime = moment()
      .utc()
      .startOf("day")
      .subtract(duration)
      .format("YYYY-MM-DD HH:MM:SS");
    const snapshot = await db
      .collection("heat_maps")
      .where(dId, ">=", dateTime)
      .get();
    let data = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    });
    if (data.length) {
      // data = data.reverse();
      this.setState({ data });
    }
  }

  renderPrevArrow = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <FontAwesomeIcon
        icon={faAngleDoubleLeft}
        onClick={onClickHandler}
        style={{
          ...getArrowStyles(
            this.props.isDol,
            this.props.isMob,
            this.props.isTab,
            true,
            false
          ),
          color: "#000000",
          padding: this.props.isDol ? "10px" : "5px",
          cursor: "pointer",
          backgroundColor: "#dbedff",
          fontSize: this.props.isDol ? "50px" : "30px",
          border: 0
        }}
      />
    );

  renderNextArrow = (onClickHandler, hasNext, label) =>
    hasNext && (
      <FontAwesomeIcon
        icon={faAngleDoubleRight}
        onClick={onClickHandler}
        style={{
          ...getArrowStyles(
            this.props.isDol,
            this.props.isMob,
            this.props.isTab,
            false,
            true
          ),
          color: "#000000",
          padding: this.props.isDol ? "10px" : "5px",
          cursor: "pointer",
          backgroundColor: "#dbedff",
          fontSize: this.props.isDol ? "50px" : "30px",
          border: 0
        }}
      />
    );

  render() {
    if (this.state.data.length) {
      return (
        <div
          style={{
            margin: "auto",
            // background: "yellow",
            width: "80%"
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end"
            }}
          >
            <AboutView
              content={
                "A series of pictorial representations depicting pollution level across IIT Delhi"
              }
              size={"small"}
            />
          </div>
          <Carousel
            selectedItem={this.state.data.length - 1}
            showThumbs={false}
            showArrows={true}
            showIndicators={false}
            showStatus={false}
            renderArrowNext={this.renderNextArrow}
            renderArrowPrev={this.renderPrevArrow}
          >
            {this.state.data.map((data, index) => {
              let imgUrl =
                "https://storage.googleapis.com/heat_maps/" +
                data.destinationName;
              return (
                <div key={index}>
                  <ProgressiveImage
                    delay={500}
                    src={imgUrl}
                    placeholder={
                      "https://placeholder.pics/svg/1000x1000/DEDEDE/FFDCDB"
                    }
                  >
                    {src => <img src={src} alt="heat_map" />}
                  </ProgressiveImage>
                </div>
              );
            })}
          </Carousel>
        </div>
      );
    }

    return (
      <div style={{ margin: "auto", textAlign: "center" }}>
        {"Empty heat maps"}
      </div>
    );
  }
}

class DelhiHeatMap extends React.Component {
  state = {
    data: []
  };
  async componentDidMount() {
    const db = this.props.firebase.db;
    const dId = this.props.firebase.documentId;
    let duration = moment.duration({ hours: 5, minutes: 30 });
    let dateTime = moment()
      .utc()
      .startOf("day")
      .subtract(duration)
      .format("YYYY-MM-DD HH:MM:SS");
    const snapshot = await db
      .collection("delhi_heat_maps")
      .where(dId, ">=", dateTime)
      .get();
    let data = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    });
    if (data.length) {
      // data = data.reverse();
      this.setState({ data });
    }
  }

  renderPrevArrow = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <FontAwesomeIcon
        icon={faAngleDoubleLeft}
        onClick={onClickHandler}
        style={{
          ...getArrowStyles(
            this.props.isDol,
            this.props.isMob,
            this.props.isTab,
            true,
            false
          ),
          color: "#000000",
          padding: this.props.isDol ? "10px" : "5px",
          cursor: "pointer",
          backgroundColor: "#dbedff",
          fontSize: this.props.isDol ? "50px" : "30px",
          border: 0
        }}
      />
    );

  renderNextArrow = (onClickHandler, hasNext, label) =>
    hasNext && (
      <FontAwesomeIcon
        icon={faAngleDoubleRight}
        onClick={onClickHandler}
        style={{
          ...getArrowStyles(
            this.props.isDol,
            this.props.isMob,
            this.props.isTab,
            false,
            true
          ),
          color: "#000000",
          padding: this.props.isDol ? "10px" : "5px",
          cursor: "pointer",
          backgroundColor: "#dbedff",
          fontSize: this.props.isDol ? "50px" : "30px",
          border: 0
        }}
      />
    );

  render() {
    if (this.state.data.length) {
      return (
        <div
          style={{
            margin: "auto",
            // background: "yellow",
            width: "80%"
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end"
            }}
          >
            <AboutView
              content={
                "A pictorial representation depicting pollution level across Delhi for past 24 hours"
              }
              size={"small"}
            />
          </div>
          <Carousel
            selectedItem={this.state.data.length - 1}
            showThumbs={false}
            showArrows={true}
            showIndicators={false}
            showStatus={false}
            renderArrowNext={this.renderNextArrow}
            renderArrowPrev={this.renderPrevArrow}
          >
            {this.state.data.map((data, index) => {
              let imgUrl =
                "https://storage.googleapis.com/delhi_heat_maps/" +
                data.destinationName;
              return (
                <div key={index}>
                  <ProgressiveImage
                    delay={500}
                    src={imgUrl}
                    placeholder={
                      "https://placeholder.pics/svg/1000x1000/DEDEDE/FFDCDB"
                    }
                  >
                    {src => <img src={src} alt="delhi_heat_map" />}
                  </ProgressiveImage>
                </div>
              );
            })}
          </Carousel>
        </div>
      );
    }

    return (
      <div style={{ margin: "auto", textAlign: "center" }}>
        {"Empty heat maps"}
      </div>
    );
  }
}

const TabView = props => {
  return (
    <Tabs
      value={props.tabValue}
      onChange={props.handleTabChange}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab label="IIT Delhi" style={{ fontSize: "18px" }} />
      <Tab label="Delhi" style={{ fontSize: "18px" }} />
    </Tabs>
  );
};

const HeatMapContainer = props => {
  const isDol = useMediaQuery({
    query: "(min-device-width: 1224px)"
  });
  const isTab = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMob = useMediaQuery({ maxWidth: 767 });
  const [tabValue, setValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <br />
      <TabView tabValue={tabValue} handleTabChange={handleTabChange} />
      <br />
      <br />
      {tabValue === 0 && (
        <IITDelhiHeatMap {...props} isDol={isDol} isMob={isMob} isTab={isTab} />
      )}
      {tabValue === 1 && (
        <DelhiHeatMap {...props} isDol={isDol} isMob={isMob} isTab={isTab} />
      )}
    </div>
  );
};

export default withFirebase(HeatMapContainer);
