import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as ROUTES from "../../constants/routes";
// import MapHome from "../../pages/MapHome";
import DataHome from "../../pages/DataHome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

toast.configure({
  autoClose: 5000,
  draggable: false
});

const hist = createBrowserHistory();
const theme = createMuiTheme({
  typography: {
    fontFamily: `"Montserrat",sans-serif`
  }
});
const App = () => (
  <MuiThemeProvider theme={theme}>
    <Router history={hist}>
      <div>
        <Switch>
          {/* <Route exact path={ROUTES.HOME} component={MapHome} /> */}
          <Route exact path={ROUTES.DATA_HOME} component={DataHome} />
          <Route component={DataHome} />
        </Switch>
      </div>
    </Router>
  </MuiThemeProvider>
);
export default App;
