import React from "react";
import Typography from "@material-ui/core/Typography";
import Title from "../Title";
import { Nodes } from "../../../../utils/readers";
import Card from "../../../Card/Card";
import { withFirebase } from "../../../Firebase/index.js";
import moment from "moment";
import AboutView from "../../../AboutView";

class ActiveNodeStats extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribe = null;
    this.timer = null;
    this.state = {
      activeNodes: Nodes.length,
      timeInterval: 60000 // 60 seconds
    };
  }

  handleDataFetching = async () => {
    let twoMinBeforeEpoch = moment()
      .subtract(1, "minutes")
      .unix();
    const db = this.props.firebase.db;
    let activeNodes = 0;
    let promises = [];
    for (let node of Nodes) {
      const p = db
        .collection("nodes")
        .doc(node.uid)
        .collection("data")
        .where("createdAt", ">=", twoMinBeforeEpoch)
        .limit(1)
        .get();
      promises.push(p);
    }
    const all = Promise.all(promises);
    let results = await all.catch(e => {
      console.log(e);
    });
    results.forEach(snapshot => {
      const data = snapshot.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      if (data.length) {
        activeNodes++;
      }
    });
    this.setState({ activeNodes });
  };

  componentDidMount() {
    this.handleDataFetching();
    this.timer = setInterval(this.handleDataFetching, this.state.timeInterval);
  }

  componentWillUnmount = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  render = () => {
    return (
      <Card style={{ padding: "10px", marginTop: "0px", height: "150px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Title>Active Nodes</Title>
          <InfoView />
        </div>
        <div
          style={{
            margin: "20px",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <Typography component="p" variant="h2">
            {`${this.state.activeNodes}`}
          </Typography>
          <Typography
            component="p"
            variant="h5"
            style={{ marginTop: "auto" }}
          >{`/ ${Nodes.length}`}</Typography>
        </div>
      </Card>
    );
  };
}

const InfoView = () => (
  <AboutView
    content={
      "Shows the number of nodes that are active against the total number of nodes in the network"
    }
    size={"small"}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
  />
);
export default withFirebase(ActiveNodeStats);
