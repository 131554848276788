import React from "react";
import { withFirebase } from "../../Firebase";
import moment from "moment";
import Chart from "react-google-charts";
import CircularProgressView from "../../CircularProgress";
import { formatDataForLineGraph } from "../../../utils/data";

class ResidualPlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      readerVal: []
    };
  }

  runQuery = async nodeVal => {
    this.setState({ isLoading: true });
    const db = this.props.firebase.db;

    let promises = [];
    let results = [];

    let residualPromise = db
      .collection("residuals")
      .doc(nodeVal)
      .collection("data")
      .where(
        "createdAt",
        ">=",
        moment()
          .subtract(7, "days")
          .startOf("day")
          .unix()
      )
      .where(
        "createdAt",
        "<=",
        moment()
          .endOf("day")
          .unix()
      )
      .get();
    promises.push(residualPromise);
    const all = Promise.all(promises);

    // get the result of all the promises
    results = await all.catch(e => {});

    // format data for table
    if (results.length === 1) {
      let data = results[0].docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      let filteredData = formatDataForLineGraph(data, "Residual");
      this.setState({ readerVal: filteredData });
    }

    this.setState({ isLoading: false });
  };

  componentDidMount = () => {
    this.runQuery(this.props.nodeId);
  };

  componentDidUpdate = prevProps => {
    if (prevProps.nodeId !== this.props.nodeId) {
      this.runQuery(this.props.nodeId);
    }
  };
  render = () => {
    return (
      <ResidualPlotView
        isLoading={this.state.isLoading}
        readerVal={this.state.readerVal}
      />
    );
  };
}
const ResidualPlotView = ({ isLoading, readerVal }) => {
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          height: "350px"
        }}
      >
        <CircularProgressView />
      </div>
    );
  }
  let emptyData = "Empty Data. Please Select A Node or A Different Node";
  if (readerVal.length <= 1) {
    return (
      <div
        style={{
          textAlign: "center",
          height: "350px"
        }}
      >
        {emptyData}
      </div>
    );
  }
  return (
    <div id="chart">
      <Chart
        width={"100%"}
        height={350}
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={readerVal}
        options={{
          legend: { position: "none" },
          title: `Residual for ${moment().format("DD/MM/YYYY")}`,
          colors: ["purple"],
          hAxis: {
            title: "Date,Time",
            slantedText: false,
            titleTextStyle: {
              fontName: "Montserrat"
            }
          },
          vAxis: {
            title: "PM 2.5 μg/m³",
            titleTextStyle: {
              fontName: "Montserrat"
            }
          },
          bars: "vertical",
          curveType: "function"
        }}
      />
    </div>
  );
};

export default withFirebase(ResidualPlot);
