import React from "react";
import "./legend.css";
import { PM_DETAILS } from "../../utils/utils";

export default class Legend extends React.Component {
  render = () => {
    return (
      <div
        className=" legend container "
        style={{
          position: "absolute",
          bottom: "20px",
          left: "20px"
        }}
      >
        {PM_DETAILS.map((pmDetail, index) => {
          return (
            <div className="legend-item" key={index}>
              <i style={{ background: pmDetail.c }} />
              {`${pmDetail.r[0]} - ${pmDetail.r[1]}`}
            </div>
          );
        })}
      </div>
    );
  };
}
//<div class="legend-item"><i style="background:#8AB8FF"></i> &lt; 50</div><div class="legend-item"><i style="background:#FFEE52"></i> 50–100</div><div class="legend-item"><i style="background:#F2CC0C"></i> 100–150</div><div class="legend-item"><i style="background:#FF780A"></i> 150–200</div><div class="legend-item"><i style="background:#E02F44"></i> 200–250</div><div class="legend-item"><i style="background:#C4162A"></i> 250+</div>
