import React from "react";
import { withFirebase } from "../../Firebase";
import { formatData } from "../../../utils/data";
import moment from "moment";
import CircularProgressView from "../../CircularProgress/index.js";
import Chart from "react-google-charts";
import AboutView from "../../AboutView";

class GraphView extends React.Component {
  state = {
    readerVal: [],
    isLoading: false
  };

  runQuery = async nodeVal => {
    this.setState({ isLoading: true });
    const db = this.props.firebase.db;
    const snapshot = await db
      .collection("reports")
      .doc(nodeVal)
      .collection("data")
      .where(
        "createdAt",
        ">=",
        moment()
          .startOf("day")
          .unix()
      )
      .where(
        "createdAt",
        "<=",
        moment()
          .endOf("day")
          .unix()
      )
      .get();
    const data = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    });
    if (!data.length) {
      // handleToast('error', "No Data Found")
    }
    let readerData = formatData(data, "avg_pm2_5", "PM 2.5");
    this.setState({ readerVal: readerData, isLoading: false });
  };

  componentDidMount = () => {
    this.runQuery(this.props.nodeId);
  };

  componentDidUpdate = prevProps => {
    if (prevProps.nodeId !== this.props.nodeId) {
      this.runQuery(this.props.nodeId);
    }
  };

  render = () => {
    return (
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
        >
          <AboutView
            content={
              "Graphical representation for hourly PM 2.5 value for selected node"
            }
            size={"small"}
          />
        </div>
        <MyResponsiveLine
          readerVal={this.state.readerVal}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  };
}

const MyResponsiveLine = ({ readerVal, isLoading }) => {
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          height: "350px"
        }}
      >
        <CircularProgressView />
      </div>
    );
  }
  let emptyData = "Empty Data. Please Select A Node or A Different Node";
  if (readerVal.length <= 1) {
    return (
      <div style={{ textAlign: "center", height: "350px" }}>{emptyData}</div>
    );
  }
  return (
    <div id="chart">
      <Chart
        width={"100%"}
        height={350}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={readerVal}
        options={{
          legend: { position: "none" },
          hAxis: {
            title: "Date,Time",
            titleTextStyle: {
              fontName: "Montserrat"
            }
          },
          vAxis: {
            title: "PM 2.5 μg/m³",
            titleTextStyle: {
              fontName: "Montserrat"
            }
          },
          series: {
            1: { curveType: "function" }
          }
        }}
        rootProps={{ "data-testid": "2" }}
      />
    </div>
  );
};

export default withFirebase(GraphView);
