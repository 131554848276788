import React from "react";
import { withFirebase } from "../Firebase";
import { formatCandleData } from "../../utils/data";
import moment from "moment";
import Chart from "react-google-charts";
import CircularProgressView from "../CircularProgress";
import AboutView from "../AboutView";

class CandleStickGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      readerVal: []
    };
  }

  runQuery = async nodeVal => {
    this.setState({ isLoading: true });
    const db = this.props.firebase.db;
    const snapshot = await db
      .collection("candles")
      .doc(nodeVal)
      .collection("data")
      .where(
        "createdAt",
        ">=",
        moment()
          .subtract(7, "days")
          .unix()
      )
      .where("createdAt", "<=", moment().unix())
      .get();
    const data = snapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    });
    if (!data.length) {
      // handleToast('error', "No Data Found")
    }
    let readerVal = formatCandleData(data);
    this.setState({ readerVal, isLoading: false });
  };

  componentDidMount = () => {
    this.runQuery(this.props.nodeId);
  };

  componentDidUpdate = prevProps => {
    if (prevProps.nodeId !== this.props.nodeId) {
      this.runQuery(this.props.nodeId);
    }
  };

  render() {
    return (
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
        >
          <AboutView
            content={
              "Candle stick plot to depict daily PM 2.5 value for selected node. Hover on the candle to see more info"
            }
            size={"small"}
          />
        </div>
        <ChartView
          isLoading={this.state.isLoading}
          readerVal={this.state.readerVal}
        />
      </div>
    );
  }
}

const ChartView = ({ isLoading, readerVal }) => {
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          height: "350px"
        }}
      >
        <CircularProgressView />
      </div>
    );
  }

  let emptyData = "Empty Data. Please Select A Node or A Different Node";
  if (readerVal.length <= 1) {
    return (
      <div
        style={{
          textAlign: "center",
          height: "350px",
          fontFamily: "Montserrat"
        }}
      >
        {emptyData}
      </div>
    );
  }
  return (
    <div id="chart">
      <Chart
        width={"100%"}
        height={350}
        chartType="CandlestickChart"
        loader={<div>Loading Chart</div>}
        data={readerVal}
        options={{
          legend: {
            position: "none",
            titleTextStyle: {
              fontName: "Montserrat"
            }
          },
          hAxis: {
            title: "Date",
            slantedText: false,
            titleTextStyle: {
              fontName: "Montserrat"
            }
          },
          vAxis: {
            title: "PM 2.5 μg/m³",
            titleTextStyle: {
              fontName: "Montserrat"
            }
          }
        }}
        rootProps={{ "data-testid": "1" }}
      />
    </div>
  );
};

export default withFirebase(CandleStickGraph);
