export const Nodes = [
    {
        uid: "001",
        lat: 28.546836,
        lng: 77.190509,
        loc_name: "Block 6 3rd Floor",
        mac_addr: "DC:4F:22:60:E5:74"
    },
    {
        uid: "002",
        lat: 28.545313,
        lng: 77.192368,
        loc_name: " Wind-T(CCD)",
        mac_addr: "DC:4F:22:60:E4:C4"
    },
    {
        uid: "003",
        lat: 28.545588,
        lng: 77.193018,
        loc_name: "Seminar Hall",
        mac_addr: "DC:4F:22:60:E5:93"
    },
    {
        uid: "004",
        lat: 28.542775,
        lng: 77.193166,
        loc_name: "LHC 6th floor\n",
        mac_addr: "DC:4F:22:60:E4:E8"
    },
    {
        uid: "005",
        lat: 28.542898,
        lng: 77.199994,
        loc_name: "Mehrauli Gate",
        mac_addr: "5C:CF:7F:1F:39:BA"
    },
    {
        uid: "006",
        lat: 28.545727,
        lng: 77.188141,
        loc_name: "IIT Hospital",
        mac_addr: "DC:4F:22:60:E5:15"
    },
    {
        uid: "007",
        lat: 28.548589,
        lng: 77.186157,
        loc_name: "Vidhyanchal Hostel",
        mac_addr: "DC:4F:22:60:E4:90"
    },
    {
        uid: "008",
        lat: 28.543261,
        lng: 77.193213,
        loc_name: "LHC (Ground floor- Vending Machine)",
        mac_addr: "DC:4F:22:60:E4:73"
    },
    {
        uid: "009",
        lat: 28.545253,
        lng: 77.197291,
        loc_name: "UDAIGIRI",
        mac_addr: "DC:4F:22:60:E4:FA"
    },
    {
        uid: "010",
        lat: 28.543766,
        lng: 77.192028,
        loc_name: "SENSE Lawn",
        mac_addr: "A0:20:A6:12:43:E4"
    },
    {
        uid: "011",
        lat: 28.545757,
        lng: 77.194101,
        loc_name: "Kusuma School",
        mac_addr: "A0:20:A6:13:56:D3"
    },
    {
        uid: "012",
        lat: 28.545468,
        lng: 77.183595,
        loc_name: "Faculty Guest House",
        mac_addr: "A0:20:A6:13:47:B4"
    },
    {
        uid: "013",
        lat: 28.548116,
        lng: 77.183844,
        loc_name: "Aravali hostel",
        mac_addr: "DC:4F:22:60:E4:B3"
    },
    {
        uid: "014",
        lat: 28.547207,
        lng: 77.18626,
        loc_name: "Zanskar Hostel",
        mac_addr: "DC:4F:22:60:E4:A8"
    },
    {
        uid: "015",
        lat: 28.545243,
        lng: 77.197291,
        loc_name: "Satpura Hostel",
        mac_addr: "DC:4F:22:60:E4:72"
    },
    {
        uid: "016",
        lat: 28.543736,
        lng: 77.191078,
        loc_name: "TBIU",
        mac_addr: "5C:CF:7F:1F:2A:CB"
    },
    {
        uid: "017",
        lat: 28.545426,
        lng: 77.195788,
        loc_name: "IIT MAIN GATE-GUARD CABIN",
        mac_addr: "DC:4F:22:60:E5:6A"
    },
    {
        uid: "018",
        lat: 28.545598,
        lng: 77.185229,
        loc_name: "Sports Activity Centre",
        mac_addr: "DC:4F:22:60:E5:94"
    },
    {
        uid: "019",
        lat: 28.547614,
        lng: 77.185835,
        loc_name: "Shivalik Hostel",
        mac_addr: "DC:4F:22:60:E5:10"
    },
    {
        uid: "020",
        lat: 28.544607,
        lng: 77.196902,
        loc_name: "Kailash Hostel",
        mac_addr: "DC:4F:22:60:E4:F7\n"
    },
    {
        uid: "021",
        lat: 28.546039,
        lng: 77.182666,
        loc_name: "Nilgiri Hostel",
        mac_addr: "DC:4F:22:60:E4:7E"
    },
    {
        uid: "022",
        lat: 28.542447,
        lng: 77.198579,
        loc_name: "Nursery School IIT",
        mac_addr: "5C:CF:7F:20:17:4C"
    },
    {
        uid: "023",
        lat: 28.545235,
        lng: 77.19233,
        loc_name: "DST 9th Floor",
        mac_addr: "DC:4F:22:60:E4:CB"
    },
    {
        uid: "024",
        lat: 28.548225,
        lng: 77.187874,
        loc_name: "Himadri Hostel",
        mac_addr: "DC:4F:22:60:E5:14"
    },
    {
        uid: "025",
        lat: 28.543882,
        lng: 77.193251,
        loc_name: "SBI ATM",
        mac_addr: "DC:4F:22:60:E4:A1"
    },
    {
        uid: "026",
        lat: 28.54358,
        lng: 77.198574,
        loc_name: "Main Guest House",
        mac_addr: "DC:4F:22:60:E4:B6"
    },
    {
        uid: "027",
        lat: 28.549741,
        lng: 77.184918,
        loc_name: "IIT Hostel Gate",
        mac_addr: "DC:4F:22:60:E4:9A"
    },
    {
        uid: "028",
        lat: 28.543323,
        lng: 77.19311,
        loc_name: "LHC 4th Floor",
        mac_addr: "DC:4F:22:60:E4:4C"
    },
    {
        uid: "029",
        lat: 28.539616,
        lng: 77.1988,
        loc_name: "IIT Gate(Adchini)",
        mac_addr: "DC:4F:22:60:E5:8E"
    },
    {
        uid: "030",
        lat: 28.546013,
        lng: 77.179429,
        loc_name: "IIT Gate(JNU)",
        mac_addr: "DC:4F:22:60:E5:11"
    },
    {
        uid: "031",
        lat: 28.542324,
        lng: 77.182422,
        loc_name: "IIT Gate(DMS)",
        mac_addr: "DC:4F:22:60:E4:63"
    },
    {
        uid: "032",
        lat: 28.545549,
        lng: 77.192215,
        loc_name: "CCD Inside",
        mac_addr: "DC:4F:22:60:E4:F3"
    },
    {
        uid: "033",
        lat: 28.547066,
        lng: 77.18321,
        loc_name: "Karakoram Hostel",
        mac_addr: "DC:4F:22:60:E4:8A"
    },
    {
        uid: "034",
        lat: 28.545741,
        lng: 77.19093,
        loc_name: "BLOCK 3(first floor, CARE(first right))",
        mac_addr: "AO:20:A6:12:26:7F"
    },
    {
        uid: "035",
        lat: 28.549154,
        lng: 77.184255,
        loc_name: "Jwalamukhi Hostel",
        mac_addr: "DC:4F:22:60:E5:91"
    },
    {
        uid: "036",
        lat: 28.545449,
        lng: 77.192355,
        loc_name: "DST 7th Floor(TRIPP balcony)",
        mac_addr: "DC:4F:22:60:E5:7F"
    },
    {
        uid: "037",
        lat: 28.54503,
        lng: 77.190393,
        loc_name: "Bharti Building Second Floor IOT Security Lab",
        mac_addr: "DC:4F:22:60:E5:8F"
    },
    {
        uid: "038",
        lat: 28.54463,
        lng: 77.197128,
        loc_name: "Kailash Hostel(main entrance)",
        mac_addr: "DC:4F:22:60:E4:AC"
    },
    {
        uid: "039",
        lat: 28.544591,
        lng: 77.191704,
        loc_name: "Central Library",
        mac_addr: "DC:4F:22:60:E4:F9"
    },
    {
        uid: "040",
        lat: 28.547853,
        lng: 77.188801,
        loc_name: "Girnar Hostel(opp. Joshi Xerox)",
        mac_addr: "DC:4F:22:60:E5:10"
    },
    {
        uid: "041",
        lat: 28.544721,
        lng: 77.191938,
        loc_name: "Nescafe",
        mac_addr: "A0:20:A6:13:58:A9"
    },
    {
        uid: "042",
        lat: 28.549215,
        lng: 77.184966,
        loc_name: "Kumaon Hostel",
        mac_addr: "DC:4F:22:60:E4:69"
    },
    {
        uid: "043",
        lat: 28.544124,
        lng: 77.191861,
        loc_name: "Central Workshop Entry",
        mac_addr: "DC:4F:22:60:E5:81"
    },
    {
        uid: "044",
        lat: 28.543535,
        lng: 77.192686,
        loc_name: "Central Workshop(Production Engineering Lab)",
        mac_addr: "DC:4F:22:60:E4:65"
    },
    {
        uid: "045",
        lat: 28.546493,
        lng: 77.193264,
        loc_name: "BLOCK 3(Centre for Biomedical Engineering)",
        mac_addr: "A0:20:A6:13:5B:9B"
    },
    {
        uid: "046",
        lat: 28.545635,
        lng: 77.182602,
        loc_name: "Nilgiri Stand No. 2",
        mac_addr: "DC:4F:22:60:E5:96"
    },
    {
        uid: "056",
        lat: 28.546272,
        lng: 77.190437,
        loc_name: "SIT Building Backside",
        mac_addr: "DC:4F:22:60:E4:86"
    },
    {
        uid: "057",
        lat: 28.542597,
        lng: 77.182967,
        loc_name: "DMS Building backside (Near Cafeteria)",
        mac_addr: ""
    },
    {
        uid: "058",
        lat: 28.546746,
        lng: 77.192006,
        loc_name: "BLOCK V (CART)",
        mac_addr: "84:F3:EB:73:BD:7F"
    },
    {
        uid: "101",
        lat: 28.544184,
        lng: 77.182669,
        loc_name: "41 New Campus",
        mac_addr: "84:84:84:84:84:84"
    },
    {
        uid: "102",
        lat: 28.680244,
        lng: 77.299951,
        loc_name: "Mansarovar Park, Shahdara",
        mac_addr: "84:84:84:84:84:84"
    },
    {
        uid: "059",
        lat: 28.543512,
        lng: 77.191127,
        loc_name: "FITT, Synergy Building",
        mac_addr: "84:84:84:84:84:84"
    }
];

  // script to pad integers
  // let arr = [];
  // for (let i = 1; i < 51; i++) {
  //     arr.push({ id: paddy(i, 3) })
  // }
  // function paddy(num, padlen, padchar) {
  //     var pad_char = typeof padchar !== 'undefined' ? padchar : '0';
  //     var pad = new Array(1 + padlen).join(pad_char);
  //     return (pad + num).slice(-pad.length);
  // }
