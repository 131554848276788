import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyAit7XX-ke_NN2Gvoho-xmU9g8c2aoFGQM",
  authDomain: "aerogram-iot-01.firebaseapp.com",
  databaseURL: "https://aerogram-iot-01.firebaseio.com",
  projectId: "aerogram-iot-01",
  storageBucket: "aerogram-iot-01.appspot.com",
  messagingSenderId: "1039472160642",
  appId: "1:1039472160642:web:af22b7f0e6fb11c20ee99b",
  measurementId: "G-1G75QLWMS2"
};

// const config = {
//   apiKey: "AIzaSyCNaJHV_zO7bnox0DWsWGTGqSpAt2xlEPQ",
//   authDomain: "fir-test4-787a9.firebaseapp.com",
//   databaseURL: "https://fir-test4-787a9.firebaseio.com",
//   projectId: "fir-test4-787a9",
//   storageBucket: "fir-test4-787a9.appspot.com",
//   messagingSenderId: "831309759139",
//   appId: "1:831309759139:web:7ce9a133b99393a51ab516"
// };

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.documentId = app.firestore.FieldPath.documentId();
    // this.db.settings({
    //   timestampsInSnapshots: true
    // });
  }

  onAuthUserListener = (next, fallback) => {
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(doc => {
            if (!doc.exists) {
              fallback();
            } else {
              const dbUser = doc.data();
              // default empty roles
              if (!dbUser.roles) {
                dbUser.roles = {};
              }
              // merge auth and db user
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                ...dbUser
              };
              next(authUser);
            }
          });
      } else {
        fallback();
      }
    });
  };

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** User API ***
  sendVerificationEmail = () => this.auth.currentUser.sendEmailVerification();
  user = uid => this.db.collection(`users`).doc(uid);
  users = () => this.db.collection("users").get();
  addUser = (uid, username, email, roles) =>
    this.db
      .collection("users")
      .doc(uid)
      .set({ username, email, roles }); //.add({roles});
  addPost = () => {
    return this.db
      .collection("posts")
      .add({ title: "adminpost", content: "admintcontent" });
  };
  getNodes = () => this.db.collection("nodes").get();

  // getDb = () => this.db;
}
export default Firebase;
