import React from "react";
import "./modal.css";
import "./toggle.css";
import FontAwesome from "react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChalkboard,
  faCog,
  faSearchMinus,
  faChartLine
} from "@fortawesome/free-solid-svg-icons";
import Toggle from "react-toggle";

export const SettingsModal = props => {
  const { closeModal, showLabel, handleShowLabel } = props;
  const closeicon = () => (
    <FontAwesome
      name="times"
      onClick={closeModal}
      style={{
        color: "white",
        padding: "6px",
        cursor: "pointer",
        backgroundColor: "black",
        border: 0,
        position: "absolute",
        top: "0.3rem",
        right: "0.5rem"
      }}
    />
  );

  return (
    <div className="overlay">
      <div className="content">
        {closeicon()}
        <br />
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between"
            }}
          >
            <label>
              <span>Labels</span>
            </label>
            <br />
            <Toggle defaultChecked={showLabel} onChange={handleShowLabel} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SettingsButton = props => {
  return (
    <div
      style={{
        position: "absolute",
        top: "80px",
        left: "20px"
      }}
    >
      <FontAwesomeIcon
        icon={faCog}
        onClick={props.showModal}
        style={{
          color: "#000000",
          padding: "10px",
          cursor: "pointer",
          backgroundColor: "#dbedff",
          fontSize: "40px",
          border: 0,
          position: "absolute"
          // top: "0.3rem",
          // right: "0.5rem"
        }}
      />
    </div>
  );
};

export const ZoomOutButton = props => {
  return (
    <div
      style={{
        position: "absolute",
        top: "120px",
        left: "20px"
      }}
    >
      <FontAwesomeIcon
        icon={faSearchMinus}
        onClick={props.setZoomToDelhi}
        style={{
          color: "#000000",
          padding: "10px",
          cursor: "pointer",
          backgroundColor: "#dbedff",
          fontSize: "40px",
          border: 0,
          position: "absolute"
          // top: "0.3rem",
          // right: "0.5rem"
        }}
      />
    </div>
  );
};

// export const DataAnalyticsButton = props => {
//   return (
//     <div
//       style={{
//         position: "fixed",
//         top: "120px",
//         left: "76px"
//       }}
//     >
//       <FontAwesomeIcon
//         icon={faChartLine}
//         onClick={props.redirectToData}
//         style={{
//           color: "#000000",
//           padding: "10px",
//           cursor: "pointer",
//           backgroundColor: "#dbedff",
//           fontSize: "28px",
//           border: 0,
//           position: "absolute",
//           top: "0.3rem",
//           right: "0.5rem"
//         }}
//       />
//     </div>
//   );
// };
