const Delhi = [
    {
        "uid": 10124,
        "loc_name": "Pusa",
        "lat": 28.636997,
        "lng": 77.172248
    },
    {
        "uid": 10113,
        "loc_name": "ITI Jahangirpuri",
        "lat": 28.733016,
        "lng": 77.17197
    },
    {
        "uid": 2553,
        "loc_name": "Anand Vihar",
        "lat": 28.645892,
        "lng": 77.314853
    },
    {
        "uid": 10114,
        "loc_name": "Delhi Institute of Tool Engineering",
        "lat": 28.700505,
        "lng": 77.165603
    },
    {
        "uid": 10118,
        "loc_name": "ITI Shahdra",
        "lat": 28.672114,
        "lng": 77.313832
    },
    {
        "uid": 10115,
        "loc_name": "Satyawati College",
        "lat": 28.69572,
        "lng": 77.181295
    },
    {
        "uid": 2554,
        "loc_name": "Mandir Marg",
        "lat": 28.630362,
        "lng": 77.197293
    },
    {
        "uid": 10111,
        "loc_name": "Major Dhyan Chand National Stadium",
        "lat": 28.612498,
        "lng": 77.237388
    },
    // {
    //     "uid": 10704,
    //     "loc_name": "Mother Dairy Plant",
    //     "lat": 28.620171,
    //     "lng": 77.287705
    // },
    {
        "uid": 10121,
        "loc_name": "Sonia Vihar Water Treatment Plant DJB",
        "lat": 28.710066,
        "lng": 77.24622
    },
    {
        "uid": 7024,
        "loc_name": "New Delhi US Embassy",
        "lat": 28.63576,
        "lng": 77.22445
    },
    // {
    //     "uid": 10112,
    //     "loc_name": "PGDAV College",
    //     "lat": 28.566827,
    //     "lng": 77.251418
    // },
    {
        "uid": 12466,
        "loc_name": "Sector-1",
        "lat": 28.5898,
        "lng": 77.3101
    },
    {
        "uid": 10705,
        "loc_name": "Jawaharlal Nehru Stadium",
        "lat": 28.582846,
        "lng": 77.234366
    },
    {
        "uid": 2556,
        "loc_name": "R.K. Puram",
        "lat": 28.566008,
        "lng": 77.176743
    },
    {
        "uid": 8180,
        "loc_name": "IHBAS",
        "lat": 28.6821516,
        "lng": 77.3026128
    },
    {
        "uid": 10122,
        "loc_name": "Lodhi Road",
        "lat": 28.589701,
        "lng": 77.221917
    },
    {
        "uid": 10125,
        "loc_name": "Burari Crossing",
        "lat": 28.725646,
        "lng": 77.203384
    },
    {
        "uid": 10127,
        "loc_name": "North Campus",
        "lat": 28.687716,
        "lng": 77.210232
    },
    {
        "uid": 2558,
        "loc_name": "Civil Lines",
        "lat": 28.6793308,
        "lng": 77.2280838
    },
    {
        "uid": 3715,
        "loc_name": "ITO",
        "lat": 28.6226748,
        "lng": 77.2479198
    }
]
const Dwarka = [{
    "uid": 8181,
    "loc_name": "Dwarka",
    "lat": 28.5895657,
    "lng": 77.0496539
},
{
    "uid": 10119,
    "loc_name": "National Institute of Malaria Research",
    "lat": 28.576909,
    "lng": 77.075898
}]

const Gurgaon = [{
    "uid": 12816,
    "loc_name": "Sector-51",
    "lat": 28.4227,
    "lng": 77.0667
},
{
    "uid": 8685,
    "loc_name": "Vikas Sadan Gurgaon",
    "lat": 28.4501285,
    "lng": 77.0263051
}]

const Noida = [
    // {
    //     "uid": 11865,
    //     "loc_name": "Sector - 62",
    //     "lat": 28.6245479,
    //     "lng": 77.3577104
    // },
    {
        "uid": 10112,
        "loc_name": "PGDAV College",
        "lat": 28.566827,
        "lng": 77.251418
    },
    {
        "uid": 2553,
        "loc_name": "Anand Vihar",
        "lat": 28.645892,
        "lng": 77.314853
    },
    {
        "uid": 10704,
        "loc_name": "Mother Dairy Plant",
        "lat": 28.620171,
        "lng": 77.287705
    },
    {
        "uid": 10116,
        "loc_name": "DITE Okhla",
        "lat": 28.531314,
        "lng": 77.270686
    },
    {
        "uid": 11856,
        "loc_name": "Vasundhara",
        "lat": 28.6542593,
        "lng": 77.3549349
    },
    // {
    //     "uid": 11863,
    //     "loc_name": "Sector - 125",
    //     "lat": 28.5447608,
    //     "lng": 77.3231257
    // },
    // {
    //     "uid": 12466,
    //     "loc_name": "Sector-1",
    //     "lat": 28.5898,
    //     "lng": 77.3101
    // },
    {
        "uid": 10110,
        "loc_name": "Dr. Karni Singh Shooting Range",
        "lat": 28.499727,
        "lng": 77.267095
    },
    {
        "uid": 12435,
        "loc_name": "Indirapuram",
        "lat": 28.646233,
        "lng": 77.358075
    },
    {
        "uid": 12465,
        "loc_name": "Sector-116",
        "lat": 28.56923,
        "lng": 77.393848
    },
    {
        "uid": 11273,
        "loc_name": "Sector - 125",
        "lat": 28.5447608,
        "lng": 77.3231257
    },
    {
        "uid": 11296,
        "loc_name": "Sector - 62",
        "lat": 28.6245479,
        "lng": 77.3577104
    },
    {
        "uid": 11317,
        "loc_name": "Vasundhara",
        "lat": 28.6603346,
        "lng": 77.3572563
    },
    {
        "uid": 10123,
        "loc_name": "CRRI Mathura Road",
        "lat": 28.551729,
        "lng": 77.275037
    },
    {
        "uid": 3715,
        "loc_name": "ITO",
        "lat": 28.6226748,
        "lng": 77.2479198
    },
    {
        "uid": 12463,
        "loc_name": "Knowledge Park - V",
        "lat": 28.557054,
        "lng": 77.453663
    },
    {
        "uid": 11278,
        "loc_name": "Knowledge Park - III",
        "lat": 28.47272,
        "lng": 77.482
    }
]

const Rohini = [
    {
        "uid": 10706,
        "loc_name": "Narela",
        "lat": 28.820629,
        "lng": 77.101099
    },
    {
        "uid": 10117,
        "loc_name": "Shaheed Sukhdev College of Business Studies",
        "lat": 28.732743,
        "lng": 77.118788
    },
    {
        "uid": 11267,
        "loc_name": "Pooth Khurd",
        "lat": 28.7757959,
        "lng": 77.0462514
    },
    {
        "uid": 10708,
        "loc_name": "Mundka",
        "lat": 28.68241,
        "lng": 77.030469
    },
    {
        "uid": 2555,
        "loc_name": "Punjabi Bagh",
        "lat": 28.661975,
        "lng": 77.124156
    },
    {
        "uid": 11266,
        "loc_name": "Alipur",
        "lat": 28.815691,
        "lng": 77.152491
    },
    {
        "uid": 9290,
        "loc_name": "DTU",
        "lat": 28.749722,
        "lng": 77.116281
    }
]

const Faridabad = [
    {
        "uid": 12813,
        "loc_name": "Sector 11",
        "lat": 28.376058,
        "lng": 77.315741
    },
    {
        "uid": 8672,
        "loc_name": "Sector16A Faridabad",
        "lat": 28.41012222,
        "lng": 77.313775
    },
    {
        "uid": 12434,
        "loc_name": "Nathu Colony",
        "lat": 28.3419248,
        "lng": 77.319699
    },
    {
        "uid": 10110,
        "loc_name": "Dr. Karni Singh Shooting Range",
        "lat": 28.499727,
        "lng": 77.267095
    },
    {
        "uid": 12814,
        "loc_name": "Sector 30",
        "lat": 28.4417,
        "lng": 77.3217
    },
    {
        "uid": 6971,
        "loc_name": "Sector16A Faridabad",
        "lat": 28.41012222,
        "lng": 77.313775
    }
]

export const OpenAQINodes = [
    ...Delhi, ...Gurgaon, ...Dwarka, ...Noida, ...Rohini, ...Faridabad
]

//script to parse data
// this response for Gurgaon is from https://api.waqi.info/search/?token=7ecf59ec5b148b76f2ed1c3aba6e9785e828b55a&keyword=Gurgaon
// you can also use for Delhi https://api.waqi.info/search/?token=7ecf59ec5b148b76f2ed1c3aba6e9785e828b55a&keyword=Delhi
// const data = {"status":"ok","data":[{"uid":12816,"aqi":"90","time":{"tz":"+05:30","stime":"2020-03-31 12:00:00","vtime":1585636200},"station":{"name":"Sector-51, Gurugram, India","geo":[28.4227,77.0667],"url":"india/gurugram/sector-51","country":"IN"}},{"uid":8685,"aqi":"80","time":{"tz":"+05:30","stime":"2020-03-31 12:00:00","vtime":1585636200},"station":{"name":"Vikas Sadan Gurgaon, Gurgaon, India","geo":[28.4501285,77.0263051],"url":"india/gurgaon/vikas-sadan-gurgaon","country":"IN"}}]}
// let datas = data.data; 
// let result = []
// for(let d of datas){
//     result.push({uid : d.uid,loc_name:d.station.name.split(',')[0],lat:d.station.geo[0],lng:d.station.geo[1]})
// }

// console.log(JSON.stringify(result))