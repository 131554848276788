import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Dashboard from "./views/Dashboard";
import {
  getMainListItems,
  DASHBOARD,
  REPORT,
  CANDLE,
  DECOMPOSITION,
  PREDICTION,
  HOME,
  HEATMAP
} from "../../utils/listItems";
import { Grid, Typography, List, Divider, Container } from "@material-ui/core";
import Candle from "./views/Candle";
import Prediction from "./views/Prediction";
import Decomposition from "./views/Decomposition";
import { Redirect } from "react-router-dom";
import MapHome from "../MapHome";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import HeatMap from "./views/HeatMap";

const drawerWidth = 220;

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    paddingTop: 50
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
});

// FIX MEMORY LEAKS, LOOK AT CONSOLE in CHROME
class DataHome extends React.Component {
  state = {
    open: false,
    title: HOME,
    selectedView: HOME
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleItemClick = view => {
    this.setState({ selectedView: view });
  };

  handleHiddenDrawerItemClick = view => {
    this.setState({ selectedView: view });
    this.handleDrawerToggle();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {this.state.selectedView}
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <SwipeableDrawer
              container={this.props.container}
              variant="temporary"
              open={this.state.open}
              onOpen={this.handleDrawerToggle}
              onClose={this.handleDrawerToggle}
              classes={{ paper: classes.drawerPaper }}
            >
              <List>{getMainListItems(this.handleHiddenDrawerItemClick)}</List>
              {/* <Divider /> */}
            </SwipeableDrawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{ paper: classes.drawerPaper }}
              variant="permanent"
              open
            >
              <List>{getMainListItems(this.handleItemClick)}</List>
              {/* <Divider /> */}
            </Drawer>
          </Hidden>
        </nav>
        {getView(this.state.selectedView, classes)}
        {/* {this.state.selectedView === HOME && <Redirect to="/" />} */}
      </div>
    );
  }
}

DataHome.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(DataHome);

// eventually this views will be rendered in a route
const getView = (viewName, classes) => {
  if (viewName === HOME) {
    return (
      <div
        style={{ margin: "0px", padding: "0px", width: "100%", height: "100%" }}
      >
        <Card style={{ margin: "0px", padding: "0px" }}>
          <MapHome />
        </Card>
      </div>
    );
  }

  if (viewName === DASHBOARD) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "auto"
        }}
      >
        <div className={classes.toolbar} />
        <Dashboard />
      </div>
    );
  }

  if (viewName === HEATMAP) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "auto",
          overflow: "hidden"
        }}
      >
        <div className={classes.toolbar} />
        {/* <Card style={{ margin: "0px", padding: "0px" }}> */}
        <HeatMap />
        {/* </Card> */}
      </div>
    );
  }

  if (viewName === CANDLE) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "auto"
        }}
      >
        <div className={classes.toolbar} />
        <Candle />
      </div>
    );
  }

  if (viewName === PREDICTION) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "auto"
        }}
      >
        <div className={classes.toolbar} />
        <Prediction />
      </div>
    );
  }

  if (viewName === DECOMPOSITION) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "auto"
        }}
      >
        <div className={classes.toolbar} />
        <Decomposition />
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        margin: "auto"
      }}
    >
      <div className={classes.toolbar} />
      <Dashboard />
    </div>
  );
};
