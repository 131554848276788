import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  Button,
  MenuItem
} from "@material-ui/core";
import React, { useState } from "react";

import GridItem from "../../components/Grid/GridItem";
import { Nodes } from "../../utils/readers";
const SelectorView = props => {
  const { handleSubmit } = props;
  const [selectedNode, setSelectedNode] = useState(Nodes[15].uid);
  const handleSelectChange = event => {
    setSelectedNode(event.target.value);
  };
  return (
    <GridItem xs={12} sm={12} md={12}>
      <Grid>
        <FormControl style={{ width: "100%", marginTop: "15px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
              flexWrap: "wrap",
              justifyContent: "flexStart"
            }}
          >
            <InputLabel id="demo-simple-select-label">Select Node</InputLabel>
            <Select
              id="Node"
              value={selectedNode}
              onChange={handleSelectChange}
              style={{ width: "250px", marginRight: "20px" }}
            >
              {Nodes.map((node, index) => {
                return (
                  <MenuItem
                    value={node.uid}
                    key={index}
                  >{`${node.uid} -- ${node.loc_name}`}</MenuItem>
                );
              })}
            </Select>
            <Button
              variant="outlined"
              style={{
                marginTop: "25px",
                marginBottom: "10px",
                marginRight: "40px"
              }}
              color="primary"
              onClick={e => {
                e.preventDefault();
                handleSubmit(selectedNode);
              }}
            >
              Submit
            </Button>
          </div>
        </FormControl>
      </Grid>
    </GridItem>
  );
};

export default SelectorView;
